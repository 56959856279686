import React from 'react';
import HomeAdvisor from '@cleverrealestate/clever-components-v2/dist/components/HomeAdvisor';
import {
  useUserIPGrabber,
} from '@cleverrealestate/clever-helpers';
import Layout from '../components/layout';
import SEO from '../components/Seo';

const FindTrustedContractors = () => {
  useUserIPGrabber();

  return (
    <Layout
      colorTransitionStart={25}
      shadowTransitionStart={25}
      colorTransitionLength={100}
      shadowTransitionLength={100}
    >
      <SEO
        pathname="/find-trusted-contractors/"
        type="website"
        title="Find Top Rated Home Improvement Pros"
        description={`We've partnered with HomeAdvisor to connect
         you with top rated contractors to bid on your next project.`}
      />
      <HomeAdvisor />
    </Layout>
  );
};

export default FindTrustedContractors;
